let moment = require("moment-timezone");

export function toBoolean(data) {
  if (data == null) return false;
  //
  let val = data.toString().toLowerCase();
  if (val === "true") return true;
  if (val === "1") return true;

  return false;
}

export const toHex = function (num) {
  let hex = Math.floor(num).toString(16);

  if (hex.length == 1) {
    hex = "0" + hex;
  }
  return hex;
};

export const toDate = function (val) {
  let date = val;
  if (date != null) {
    date = moment(date).format();
  }
  return date;
};
