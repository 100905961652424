/* eslint-disable */

export const webconfig = {
  web: {
    title: "application",
    description: "",
    version: "",
    isDebug: true,
  },
  application: {
    base: "",
    defaultLocale: "ja",
  },
  // storage
  storage: {
    key: "siteshot_storage",
  },
  api: {
    // ApiGatewayのAPIキー
    xApiKey: "HaCtraxkxRamitD7hv4s71l3WYjyot8B9U6DS1tt",
    // WebAPIのAPIキー
    apikey: "eTmszHnFGKLOn4nNcIxZ",
    //urlEncryptKey: "Kir7d6yQ64npncmfRvWF", // 空なら暗号化しない
    urlEncryptKey: "", // 空なら暗号化しない
    // 暗号化したレスポンスを複合化する際のキー
    responseEcrypt: {
      iv: "bbbbbbbbbbbbbbbb",
      key: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    },

    // 💬develop
    // baseURL: "https://localhost:44303", // http通信WebAPIベースURL
    // ⭕releasse
    baseURL: "https://api.siteshot.link", // http通信WebAPIベースURL

    // 連携が必要な他APIのURL
    drawUrl: "https://draw.siteshot.link",
    screenshotUrl: "https://screenshot.siteshot.link",
  },

  resource: {
    // develop
    // message: "http://localhost:8080/resources/messages/message.json",
    // sns: "http://localhost:8080/resources/sns/sns.json",
    // policy: "http://localhost:8080/resources/policies/policy.md",
    // policyIndex: "http://localhost:8080/resources/policies/policy.index.md",
    // faq: "http://localhost:8080/resources/faqs/faq.json",
    // releaseNote: "http://localhost:8080/resources/releases/releaseNote.json",

    // releasse
    message: "https://siteshot.link/resources/messages/message.json", // corsさせているのでlocalhostからアクセスしないように
    sns: "https://siteshot.link/resources/sns/sns.json",
    policy: "https://siteshot.link/resources/policies/policy.md",
    policyIndex: "https://siteshot.link/resources/policies/policy.index.md",
    faq: "https://siteshot.link/resources/faqs/faq.json",
    releaseNote: "https://siteshot.link/resources/releases/releaseNote.json",
  },
  disqus: {
    embedJs: "https://siteshot.disqus.com/embed.js",
  },
  pusher: {
    apikey: "b33551258ce1368a7d3a",
    cluster: "ap3",
    // pusherをコンソール出力する
    logToConsole: false,
  },

  google: {
    adsense: {
      client: "ca-pub-3963691237028558",
      slot: "5470192838",
    },
    // form
    form: {
      url: "https://forms.gle/Q4wfesp8SZ5mQv296",
    },

    recaptcha: {
      siteKey: "6Lc4W7ciAAAAAP4JI10vGlkTRUKh32RtvyX1emRZ",
    },

    analytics: {
      id: "G-0QKWDRTQDV",
    },
  },
};
