<template>
  <div id="disqus_thread"></div>
</template>

<script>
// 設定ファイル読み込み
import { webconfig } from "@/web-configs";

export default {
  name: "PostDisqus",

  props: {
    identifier: {
      type: String,
      // default: () => window.location.pathname,
      required: true,
    },
  },
  methods: {
    setBaseConfig(disqusConfig) {
      let identifier = `${this.identifier}`;
      //let identifier = `123`;

      const defaultConfig = {
        url: document.baseURI,
        // identifier: this.$route ? this.$route.path : window.location.pathname,
        identifier: identifier,
      };

      Object.assign(disqusConfig.page, defaultConfig);
    },
  },

  mounted() {
    // https://help.disqus.com/en/articles/1717084-javascript-configuration-variables
    this.$nextTick(() => {
      const setBaseConfig = this.setBaseConfig;
      window.disqus_config = function () {
        setBaseConfig(this);
      };

      (function () {
        // DON'T EDIT BELOW THIS LINE
        var d = document,
          s = d.createElement("script");
        s.src = webconfig.disqus.embedJs;
        s.setAttribute("data-timestamp", +new Date());
        (d.head || d.body).appendChild(s);
      })();
    });
  },
};
</script>
