import store from "@/store";
const moment = require("moment-timezone");

export default class Storage {
  constructor(http) {
    this.http = http;
  }

  get userCode() {
    return store.getters["storage/userCode"];
  }

  set userCode(userCode) {
    store.commit("storage/setUserCode", userCode);
  }

  get userKey() {
    return store.getters["storage/userKey"];
  }

  set userKey(userKey) {
    store.commit("storage/setUserKey", userKey);
  }

  get shots() {
    let shots = store.getters["storage/shots"];
    if (!Array.isArray(shots)) return [];

    // 無限ループするのでクローンして退避
    shots = shots.map((a) => Object.assign({}, a));

    // 出力時に並び替えを行う
    shots.sort((a, b) => {
      const am = moment(a.date);
      const bm = moment(b.date);
      if (bm.isAfter(am)) return 1;
      if (am.isAfter(bm)) return -1;
      return 0;
    });
    return shots;
  }

  set shots(shots) {
    store.commit("storage/setShots", shots);
  }

  getShot(shortCode) {
    // TODO this.shots を使うとinifinty roop になってしまう（なぜ？）
    const shots = this.shots;
    // const shots = store.getters["storage/shots"];
    const currentShot = shots.find((a) => a.shortCode == shortCode);
    if (currentShot != null) return currentShot;
    // null落ち防止のためなくてもオブジェクトは返す
    return {
      shortCode: shortCode,
    };
  }
  addShot(shortCode, secretCode, thumbnailData) {
    // setterを動かすためいったん出す
    const shots = Object.assign([], this.shots);

    // 追加されていたら追加せず見つかったものを返す
    {
      const currentShot = shots.find((a) => a.shortCode == shortCode);

      if (currentShot != null) return currentShot;
    }
    //
    const shot = {
      shortCode: shortCode,
      secretCode: secretCode,
      thumbnailData: thumbnailData,
      date: new Date(),
    };
    shots.push(shot);
    //
    this.shots = shots;
    //
    return shot;
  }

  publishShot(shortCode, publishType) {
    const shots = this.shots; // setterを動かすためいったん出す

    for (const shot of shots.filter((a) => a.shortCode == shortCode)) {
      shot.publishType = publishType;
    }

    //
    this.shots = shots;
  }

  changeShotSecretCode(shortCode, newSecretCode) {
    const shots = this.shots; // setterを動かすためいったん出す

    for (const shot of shots.filter((a) => a.shortCode == shortCode)) {
      shot.secretCode = newSecretCode;
    }

    //
    this.shots = shots;
  }

  toggleFavoriteShot(shortCode, thumbnailData) {
    // なければ追加する
    this.addShot(shortCode, null, null, thumbnailData);
    // ここでデータ更新
    {
      const shots = this.shots; // setterを動かすためいったん出す
      for (const shot of shots.filter((a) => a.shortCode == shortCode)) {
        // nullもありうるのでこのやり方にする
        shot.isFavorite = shot.isFavorite == true ? false : true;
      }
      //
      this.shots = shots;
    }
  }

  deleteShot(shortCode) {
    let shots = this.shots; // setterを動かすためいったん出す

    shots = shots.filter((a) => a.shortCode != shortCode);

    //
    this.shots = shots;
  }

  clearShot() {
    this.shots = [];
  }

  async createUser(recaptchaToken) {
    console.log("createUser", recaptchaToken);

    console.log(" this.http", this.http);
    // throw new Error("test");

    const success = await this.http.post("user", {
      code: this.userCode,
      key: this.userKey,
      // recaptchaトークン
      recaptchaToken: recaptchaToken,
      // FIXME:ユーザ登録用トークン ハードコーディング中
      createToken: "seAo9thTH/o3DXH1Cv4cEBal+wbAUG8xtcDFJ+eQYoc=",
      createTokenKey: "BSlmMR9SPtnIlP9cfLn3dB7hGCitVKEy",
    });
    const data = success.data;

    this.userCode = data.code;
    this.userKey = data.key;
  }
}
