<template>
  <v-dialog
    scrollable
    :value="inputedValue"
    fullscreen
    transition="scale-transition"
  >
    <!-- <v-overlay :value="inputedValue" opacity="0.5"> -->
    <v-card color="rgb(0, 0, 0, 0.6)" flat tile elevation="0">
      <!-- <v-card tile elevation="0"> -->
      <v-card-title class="pa-0">
        <!-- <v-app-bar app color="transparent" dense flat> -->
        <v-btn icon @click="inputedValue = false" fab>
          <v-icon color="white" v-text="'mdi-close-circle-outline'" />
        </v-btn>
        <!-- </v-app-bar> -->
        <v-spacer />
        <WFileDownload :src="src" :fileName="fileName" />
      </v-card-title>
      <v-card-text>
        <v-img :src="src">
          <!-- <v-app-bar color="transparent" dense flat>
        <span>https://hohohohoho.com!</span>
        <v-spacer />
        <span>https://hohohohoho.com</span>
      </v-app-bar>
      <v-footer absolute color="transparent">
        <v-spacer />
       <span>https://hohohohoho.com</span> 
      </v-footer> -->
        </v-img>
      </v-card-text>
      <v-card-actions>
        <WCopyToClipBoard :value="src">
          <template v-slot:default="{ on, attrs, click }">
            <a :href="src" class="text-caption text-none white--text">
              {{ src }}
            </a>
            <v-icon
              class="ml-2"
              v-text="'mdi-content-copy'"
              color="white"
              v-bind="attrs"
              v-on="on"
              left
              small
              @click="click"
            />
          </template>
        </WCopyToClipBoard>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-overlay> -->
</template>
<script>
export default {
  components: {},

  model: {
    prop: "value",
    event: "change",
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    inputedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },

  data: () => ({}),

  methods: {
    onLoaded() {},
  },

  watch: {
    // 表示時
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },

  created() {},
};
</script>
