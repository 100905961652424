<template>
  <v-card outlined>
    <v-card-text>
      <!-- <adsense
        :ad-client="adsense.client"
        :ad-slot="adsense.slot"
        ad-style="display: block"
        ad-format="fluid"
        adtest="on"
      ></adsense> -->

      <adsense
        :data-ad-client="adsense.client"
        :data-ad-slot="adsense.slot"
        data-full-width-responsive="true"
      ></adsense>
    </v-card-text>
  </v-card>
</template>

<script>
import { webconfig } from "@/web-configs";

export default {
  components: {},

  props: {},

  computed: {
    adsense() {
      return webconfig.google.adsense;
    },
  },

  data: () => ({}),

  methods: {},
};
</script>
