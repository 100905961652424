import CryptoJS from "crypto-js";

// https://kagasu.hatenablog.com/entry/2020/04/25/174242
export function encrypt(str, ivStr, keyStr) {
  const key = CryptoJS.enc.Utf8.parse(keyStr);
  const iv = CryptoJS.enc.Utf8.parse(ivStr);
  // const str = "helloworld";

  // 暗号化する
  let encryptedWordArray = CryptoJS.AES.encrypt(str, key, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: iv,
  });

  // base64に変換する
  // const encryptedBase64String = encryptedWordArray.ciphertext.toString(CryptoJS.enc.Base64)
  const encryptedBase64String = encryptedWordArray.toString();

  return encryptedBase64String;
}

export function decrypt(encryptedBase64String, ivStr, keyStr) {
  const key = CryptoJS.enc.Utf8.parse(keyStr);
  const iv = CryptoJS.enc.Utf8.parse(ivStr);
  // const str = "helloworld";

  // // 暗号化する
  // let encryptedWordArray = CryptoJS.AES.encrypt(str, key, {
  //   mode: CryptoJS.mode.CBC,
  //   padding: CryptoJS.pad.Pkcs7,
  //   iv: iv,
  // });

  // // base64に変換する
  // // const encryptedBase64String = encryptedWordArray.ciphertext.toString(CryptoJS.enc.Base64)
  // const encryptedBase64String = encryptedWordArray.toString();

  // base64からwordarrayに変換する
  const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedBase64String);

  // 複合する
  const decryptedWordArray = CryptoJS.AES.decrypt(
    { ciphertext: encryptedWordArray },
    key,
    {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: iv,
    }
  );
  return decryptedWordArray.toString(CryptoJS.enc.Utf8);
}
