import { toHex } from "@/util/convert";

// [255,255,255] -> #ffffff
export function rgb2hex(rgb, prefex = "#") {
  return `${prefex}${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`;
}

// #ffffff - >[255,255,255]
export function hex2rgb(hex) {
  if (hex.slice(0, 1) == "#") hex = hex.slice(1);
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);

  // [r,g,b]でリターン
  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
    str
  ) {
    return parseInt(str, 16);
  });
}

// [255,255,255] -> [h,s,l]
export function rgb2hsl(r255, g255, b255) {
  var r = r255 / 255;
  var g = g255 / 255;
  var b = b255 / 255;

  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var diff = max - min;

  var h = 0;
  var l = (max + min) / 2;
  var s = diff / (1 - Math.abs(max + min - 1));

  switch (min) {
    case max:
      h = 0;
      break;

    case r:
      h = 60 * ((b - g) / diff) + 180;
      break;

    case g:
      h = 60 * ((r - b) / diff) + 300;
      break;

    case b:
      h = 60 * ((g - r) / diff) + 60;
      break;
  }

  return [h, s, l];
}

// [h,s,l] -> [255,255,255]
export function hsl2rgb(h, s, l) {
  const h360 = h % 360; // hueは360度までに収める。

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const hp = h360 / 60;
  const x = c * (1 - Math.abs((hp % 2) - 1));

  let r1;
  let g1;
  let b1;

  if (hp >= 0 && hp <= 1) {
    r1 = c;
    g1 = x;
    b1 = 0;
  } else if (hp >= 1 && hp <= 2) {
    r1 = x;
    g1 = c;
    b1 = 0;
  } else if (hp >= 2 && hp <= 3) {
    r1 = 0;
    g1 = c;
    b1 = x;
  } else if (hp >= 3 && hp <= 4) {
    r1 = 0;
    g1 = x;
    b1 = c;
  } else if (hp >= 4 && hp <= 5) {
    r1 = x;
    g1 = 0;
    b1 = c;
  } else if (hp >= 5 && hp < 6) {
    r1 = c;
    g1 = 0;
    b1 = x;
  } else {
    r1 = 0;
    g1 = 0;
    b1 = 0;
  }

  const m = l - 0.5 * c;
  const r = r1 + m;
  const g = g1 + m;
  const b = b1 + m;

  // 上の計算で0.1から1.0までのRGB値が得られるので、255倍する。
  const r255 = r * 255;
  const g255 = g * 255;
  const b255 = b * 255;

  return [r255, g255, b255];
}

// サーモグラフィの作成
export function thermo(val) {
  const sigmoid = function (x, offset, gain) {
    return (Math.tanh(((x + offset) * gain) / 2) + 1) / 2;
  };

  val = val * 2 - 1;
  let red = sigmoid(val, -0.2, 10);
  let blue = 1 - sigmoid(val, 0.2, 10);
  let green = sigmoid(val, 0.6, 10) + (1 - sigmoid(val, -0.6, 10));
  green = green - 1.0;

  return [red * 255, green * 255 * 0.9, blue * 255];
}
