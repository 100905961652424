export default class AppException extends Error {
  constructor(code = "bar", message = "", ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppException);
    }

    this.name = "AppException";
    // Custom debugging information
    this.code = code;
    this.message = message;
    this.date = new Date();
  }
}
