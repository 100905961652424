import { AppRecapchaException } from "@/exceptions";
// const { v4: uuidv4 } = require("uuid");

export default {
  computed: {},
  data: () => ({
    scrollY: 0,

    windowWidth: 0,
    windowHeight: 0,
  }),

  methods: {
    publishColor(isPublish) {
      return isPublish ? "#90CAF9" : "#FFCDD2";
    },
    async recaptchaAsync(action) {
      try {
        // const requestCode = uuidv4().replace(/-/g, "");

        //
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha(action);
        return token;
      } catch (error) {
        this.$application.app.showError(
          null,
          "recaptcha error",
          "recaptcha作成時にエラーが発生しました"
        );
        throw AppRecapchaException(error);
      }
    },

    // jsonに変換を行えなければ空オブジェクトを返す
    tryParseJson(text) {
      try {
        return JSON.parse(text);
      } catch (_ex) {
        return {};
      }
    },
    concatErrors(arr1, arr2) {
      let a = [];
      let b = [];
      if (Array.isArray(arr1)) a = arr1;
      if (Array.isArray(arr2)) b = arr2;
      //
      return a.concat(b);
    },

    // 受け取ったエラーを画面エラーの項目に合うようにアトリビュートをチェックして構成する
    // 追加項目への対応も可能なように可変長=残余引数で受け付ける
    sanitizeError(error1, error, ...args) {
      // 逆参照してしまわないようクローンする
      let err = Object.assign({}, error); // オブジェクトクローン
      let args2 = Object.assign([], args); // 配列クローン

      //
      if (!err) err = { errors: {} };
      if (!err.errors) err.errors = {};

      // 元のeditorにあるエラー
      const keys = [];
      if (error1 != null && error1.errors != null) {
        Object.keys(error1.errors).forEach((key) => {
          keys.push(key);
          // 元のエラーを受け取ったエラーにマージ
          if (!Array.isArray(err.errors[key]))
            err.errors[key] = error1.errors[key];
        });
      }
      // 追加があれば
      args2 = args2.concat(keys);

      for (const arg of args2) {
        if (!Array.isArray(err.errors[arg])) err.errors[arg] = [];
      }
      //
      return err;
    },
    // i18nからテキスト取得
    t(str, fallbackStr, args = {}) {
      return this.$application.app.trans(str, fallbackStr, args);
    },

    // エラーメッセージを取得
    e(code, fallbackStr, args = {}) {
      const str = `error.${code}.title`;
      return this.t(str, fallbackStr, args);
    },

    //
    unixTimeToDate(unixtime) {
      if (!unixtime) return null;
      return new Date(unixtime * 1000);
    },
  },

  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollY = window.scrollY;
    });

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
    //
    this.$nextTick(() => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
  },
};
