import axios from "axios";

// import i18n from "@/i18n";

import { jsonToForm } from "@/util/jsonToForm";

export default class Http {
  constructor() {}

  async get(url, request) {
    //
    //
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: url,
        // url: encryptUrl(url, webconfig.api.urlEncryptKey),
        // headers: {
        //   "Accept-Language": i18n.locale,
        // },

        params: request,
      })
        .then((response) => {
          // 成功時に実行
          resolve(response);
        })
        .catch((error) => {
          // エラー時に実行
          reject(error);
        });
    });
  }

  post(url, request) {
    //
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: url,
        // url: encryptUrl(url, webconfig.api.urlEncryptKey),
        // headers: {
        //   "Accept-Language": i18n.locale,
        //   //   Authorization: authorization,
        //   //   usercode: this.user.code,
        // },
        data: request,
      })
        .then((response) => {
          // 成功時に実行
          resolve(response);
        })
        .catch((error) => {
          // エラー時に実行
          reject(error);
        });
    });

    // return await axios({
    //   method: "post",
    //   url: encryptUrl(url, webconfig.api.urlEncryptKey),
    //   // headers: {
    //   //   Authorization: authorization,
    //   //   usercode: this.user.code,
    //   // },
    //   data: request,
    // });
  }

  async put(url, request) {
    //
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: url,
        // url: encryptUrl(url, webconfig.api.urlEncryptKey),
        // headers: {
        //   "Accept-Language": i18n.locale,
        //   //   Authorization: authorization,
        //   //   usercode: this.user.code,
        // },
        data: request,
      })
        .then((response) => {
          // 成功時に実行
          resolve(response);
        })
        .catch((error) => {
          // エラー時に実行
          reject(error);
        });
    });
  }

  async delete(url, request) {
    //
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: url,
        // url: encryptUrl(url, webconfig.api.urlEncryptKey),
        // headers: {
        //   "Accept-Language": i18n.locale,
        //   //   Authorization: authorization,
        //   //   usercode: this.user.code,
        // },
        data: request,
      })
        .then((response) => {
          // 成功時に実行
          resolve(response);
        })
        .catch((error) => {
          // エラー時に実行
          reject(error);
        });
    });
  }

  createFormData(name, files) {
    if (!Array.isArray(files)) files = [];

    // 送信用FormDataオブジェクト
    let params = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      params.append(name, file);
    }
    //
    return params;
  }

  async postUpload(url, request, files) {
    //

    // 送信用オブジェクトに入れ替える
    let params = this.createFormData("files", files); // FIXME:ハードコーディング中
    //
    params = jsonToForm(request, params);
    //
    const config = {
      headers: {
        // "Accept-Language": i18n.locale,
        // Authorization: authorization,
        // usercode: this.user.code,
        "content-type": "multipart/form-data",
        // 'Access-Control-Allow-Origin': '*',
      },

      // onUploadProgress: (progressEvent) => {
      //   this.progressTotal = progressEvent.total;
      //   this.progressLoaded = progressEvent.loaded;
      // },
    };

    //
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, config)
        .then((response) => {
          // 成功時に実行
          resolve(response);
        })
        .catch((error) => {
          // エラー時に実行
          reject(error);
        });
    });
  }
}
