<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip :color="inputedValue" bottom>
        <template v-slot:activator="{ on: tooltip }">
          <slot name="icon" :attrs="attrs" :on="{ ...tooltip, ...menu }">
            <v-avatar :size="size" :attrs="attrs" :color="inputedValue">
              <v-btn
                icon
                :color="inputedValue"
                outlined
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                small
              >
                <v-icon
                  :color="
                    $options.filters.isWhiteText(inputedValue)
                      ? 'white'
                      : 'black'
                  "
                  v-text="'mdi-brush'"
                  :size="size * 0.8"
                />
              </v-btn>
            </v-avatar>
          </slot>
        </template>
        <span
          :class="
            $options.filters.isWhiteText(inputedValue)
              ? 'white--text'
              : 'black--text'
          "
          >{{ inputedValue }}</span
        >
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <v-btn color="warning" text @click="onCanceClicked"> キャンセル </v-btn>

        <v-spacer />
        色選択
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!canCommit"
          @click="onCommitClicked"
          ripple
        >
          決定
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-color-picker
          class="mb-4"
          mode="hexa"
          v-model="data"
          :dot-size="size"
          swatches-max-height="200"
          show-swatches
        ></v-color-picker>

        <WFieldSet class="pa-2 d-flex justify-space-around" label="sample">
          <v-chip class="mx-2" :color="data">
            <v-icon v-text="icon" left />
            fill color
          </v-chip>

          <v-chip class="ma-2" :color="data" outlined>
            <v-icon v-text="icon" left />
            outlined
          </v-chip>
        </WFieldSet>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  components: {},

  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: () => "#000000",
    },

    icon: {
      type: String,
      default: () => "mdi-camera-iris",
    },
    size: {
      type: Number,
      default: () => 25,
    },
  },

  computed: {
    inputedValue: {
      get() {
        let val = this.value;
        if (val == null) return "#000000";
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },

    canCommit() {
      return this.data != null;
    },
  },

  data: () => ({
    menu: false,
    data: null,

    selected: null,
  }),

  methods: {
    onLoaded() {
      this.data = this.inputedValue;
    },

    onCommitClicked() {
      this.inputedValue = this.data;

      this.menu = false;
    },

    onCanceClicked() {
      this.menu = false;
    },
  },
  created() {
    this.onLoaded();
  },
};
</script>
