import AppException from "./AppException";

export default class AppTooManyRequestsException extends AppException {
  constructor(
    code = "U04291",
    message = "",
    approach = "",
    errors = {},
    ...params
  ) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppTooManyRequestsException);
    }
    this.name = "AppTooManyRequestsException";
    // Custom debugging information
    this.code = code;
    this.message = message;
    this.approach = approach;
    this.errors = errors;
    this.date = new Date();
  }
}
