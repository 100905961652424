import Vue from "vue";

// 設定ファイル読み込み
import { webconfig } from "@/web-configs";
//
import App from "./App";
import Storage from "./Storage";
import DataContext from "./DataContext";
import Http from "./Http";

class Application {
  //
  constructor() {
    this.http = new Http();
    this.app = new App(this.http);

    this.storage = new Storage(this.http);
    this.dataContext = new DataContext(this.http, this.app, this.storage);
  }

  get isDebug() {
    return webconfig.web.isDebug;
  }
}

const instance = new Application();

Application.installed = false;
Application.version = "1.0.0";
Application.config = {
  silent: false,
};
// Application.install = function (Vue, options) {
Application.install = function (Vue) {
  // Vueでも使えるようにprototypeにインストールする
  Vue.prototype.$application = instance;

  Vue.directive("balloon", {
    mounted: function (el) {
      el.class = "conversation-balloon other";
    },
  });
};

//  mainではなくここでやっておく必要がある
Vue.use(Application);

export default instance;
