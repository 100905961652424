// import Application from "@/applications/Application";

// Data
const state = {
  initializing: {
    show: true, // 明示的にfalseさせるようにしたいので初期状態はtrue
  },
  loading: {
    show: false,
    loader: {
      type: "circular",
      backgroundColor: "blue-grey",
      color: "lime",
      value: 0,
      message: null,
    },
  },

  // ダイアログorsnackbarに表示させるメッセージ処理用
  message: {
    show: false,
    code: null,
    title: null,
    message: null,
    approach: null,
  },

  toaster: {
    show: false,
    code: null,
    title: null,
    message: null,
    approach: null,
  },

  fatal: {
    show: false,
    code: null,
    title: null,
    message: null,
    approach: null,
  },

  error: {
    show: false,
    code: null,
    title: null,
    message: null,
    approach: null,
  },

  invalid: {
    show: false,
    code: null,
    title: null,
    message: null,
    errors: {},
  },

  jsError: {
    show: false,
    message: null,
    source: null,
    lineno: null,
    colno: null,
    error: null,
  },
  vueError: {
    show: false,
    err: null,
    vm: null,
    info: null,
  },

  setting: {
    api: {
      version: null,
    },
  },
  //
  locale: "en",
};

const mutations = {
  setInitializing(state, initializing) {
    state.initializing = initializing;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },

  setError(state, error) {
    state.error = error;
  },
  setFatal(state, fatal) {
    state.fatal = fatal;
  },
  setInvalid(state, invalid) {
    state.invalid = invalid;
  },
  setMessage(state, message) {
    state.message = message;
  },
  setToaster(state, toaster) {
    state.toaster = toaster;
  },

  setJsError(state, jsError) {
    state.jsError = jsError;
  },
  setVueError(state, vueError) {
    state.vueError = vueError;
  },

  setSetting(state, setting) {
    state.setting = setting;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
};

const getters = {
  initializing(state) {
    return state.initializing;
  },
  loading(state) {
    return state.loading;
  },
  fatal(state) {
    return state.fatal;
  },
  error(state) {
    return state.error;
  },
  invalid(state) {
    return state.invalid;
  },
  message(state) {
    return state.message;
  },
  toaster(state) {
    return state.toaster;
  },

  jsError(state) {
    return state.jsError;
  },
  vueError(state) {
    return state.vueError;
  },
  setting(state) {
    return state.setting;
  },
  locale(state) {
    return state.locale;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
