<template>
  <v-app id="app">
    <v-overlay :value="isBlur">
      <v-container fluid>
        <!-- fatal -->
        <div v-if="$application.app.fatal.show" class="text-center">
          <div><v-icon v-text="`mdi-semantic-web`" size="250" /></div>
          <div class="mt-6">
            <div>
              <v-alert transition="fade-transition" dense>
                <v-icon
                  color="warning"
                  v-text="`mdi-alert-outline`"
                  class="mr-2"
                />
                <span v-if="$application.app.fatal.code" class="mr-2">
                  [{{ $application.app.fatal.code }}]
                </span>
                <span v-html="$application.app.fatal.message" />
              </v-alert>
              <p>{{ $application.app.fatal.approach }}</p>
            </div>
          </div>
          <!-- : 見えないクローズボタン -->
          <!-- <div class="mt-12">
            <a @click="$application.app.fatal.show = false"> &nbsp; </a>
          </div> -->
        </div>
      </v-container>
    </v-overlay>

    <!-- error -->
    <v-dialog v-model="$application.app.error.show" color="error" right bottom>
      <v-card>
        <v-card-text>
          <div class="text-center py-2">
            <div>
              <span v-if="$application.app.error.code" class="mr-2">
                [{{ $application.app.error.code }}]
              </span>
              <span> {{ $application.app.error.title }}</span>
            </div>

            <div><v-icon v-text="`mdi-semantic-web`" size="100" /></div>
            <div class="mt-6">
              <span v-html="$application.app.error.message" />
              <p>{{ $application.app.error.approach }}</p>
              <v-btn
                color="pink"
                text
                @click="$application.app.error.show = false"
              >
                Close
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- invalid -->
    <v-snackbar
      v-model="$application.app.invalid.show"
      color="pink darken-4"
      right
      bottom
    >
      <v-icon v-text="`mdi-alert-outline`" class="mr-2" />
      <span v-if="$application.app.invalid.code" class="mr-2">
        [{{ $application.app.invalid.code }}]
      </span>
      <span v-html="$application.app.invalid.message" />

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="$application.app.invalid.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- message -->

    <v-dialog v-model="$application.app.message.show" width="60vw">
      <v-card>
        <v-card-title>
          <v-spacer />

          {{ $application.app.message.title }}
          <v-spacer />
        </v-card-title>
        <v-card-text>
          <v-sheet min-height="20vh" class="text-center d-flex align-center">
            <v-spacer />
            <div>
              <span v-if="$application.app.message.code" class="mr-2">
                [{{ $application.app.message.code }}]
              </span>
              <span v-html="$application.app.message.message" />
              <p>{{ $application.app.message.approach }}</p>
            </div>
            <v-spacer />
          </v-sheet>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="pink"
            text
            @click="$application.app.message.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-if="$application.app.message.type == 'snackbar'"
      v-model="$application.app.message.show"
      color="yellow darken-4"
      right
      bottom
    >
      <v-icon v-text="`mdi-alert-outline`" class="mr-2" />
      <span v-if="$application.app.message.code" class="mr-2">
        [{{ $application.app.message.code }}]
      </span>
      <span v-html="$application.app.message.message" />

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="$application.app.message.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <router-view :class="{ blur: isBlur }" />

    <v-footer padless app>
      <v-btn
        color="pink"
        :href="formUrl"
        target="_blank"
        dark
        fab
        absolute
        app
        style="right: 50px; bottom: 50px"
      >
        <v-icon v-text="`mdi-mail`" />
      </v-btn>
    </v-footer>

    <v-footer color="transparent" dark app>
      <!-- loading -->
      <div v-if="$application.app.loading.show" class="text-center">
        <v-progress-circular
          v-if="$application.app.loading.loader.type == 'circular'"
          indeterminate
        ></v-progress-circular>

        <v-sheet
          v-if="$application.app.loading.loader.type == 'linear'"
          width="80vw"
        >
          <v-progress-linear indeterminate color="green"></v-progress-linear>
        </v-sheet>

        <v-sheet
          v-if="$application.app.loading.loader.type == 'step'"
          width="80vw"
        >
          <v-progress-linear
            :background-color="$application.app.loading.loader.backgroundColor"
            :color="$application.app.loading.loader.color"
            :value="$application.app.loading.loader.value"
          ></v-progress-linear>
        </v-sheet>

        {{ $application.app.loading.loader.message }}
      </div>
    </v-footer>

    <v-footer padless>
      <v-card flat tile class="text-center grey" width="100%">
        <v-card-title class="py-2">
          <v-row justify="center" no-gutters>
            <v-btn
              v-for="(link, i) of [
                { to: '/', label: 'Home' },
                { to: '/policy', label: 'Policy' },
                { to: '/service', label: 'Service' },
              ]"
              :key="i"
              text
              :href="link.to"
              rounded
              class="my-2 text-none"
            >
              {{ link.label }}
            </v-btn>
          </v-row>
          <v-btn
            v-for="(data, i) of sns"
            v-show="!data.disabled"
            class="mx-4"
            icon
            :href="data.href"
            target="_blank"
            :key="i"
          >
            <v-icon size="24px" v-text="`${data.icon}`" />
          </v-btn>
        </v-card-title>

        <!-- <v-card-text class="white--text pt-0">
          Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet.
          Mauris cursus commodo interdum. Praesent ut risus eget metus luctus
          accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim
        </v-card-text> -->

        <v-divider></v-divider>

        <v-card-text class="white--text">
          &copy;{{ new Date().getFullYear() }} , <strong>siteshot.link</strong>
          <span class="ml-2 text-caption font-italic">
            {{ $application.app.version }}
          </span>

          <!-- 言語切り替えはとりあえずコメントアウト -->
          <!-- <span class="ml-4">
            language:
            <WCountryFlagPicker v-model="$application.app.locale" />
          </span> -->
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { webconfig } from "@/web-configs";

export default {
  name: "App",
  components: {},

  data: () => ({
    sns: {},
  }),

  computed: {
    isBlur() {
      return (
        this.$application.app.initializing.show ||
        this.$application.app.fatal.show
      );
    },
    formUrl() {
      return webconfig.google.form.url;
    },
  },

  async mounted() {
    //
    this.$application.app.locale = webconfig.application.defaultLocale;
    // 言語(ストレージ)
    await this.$application.app.loadI18n();
    // SNS対応(ストレージ)
    {
      this.sns = await this.$application.app.loadSns();
    }
    // 設定(API側)
    await this.$application.app.loadAppSetting();

    // app初期化をとりあえず
    this.$application.app.initializing.show = false;
  },

  // vueレベルのエラーハンドリング
  errorCaptured(error, vm, info) {
    const err = {
      show: true,
      err: error,
      vm: vm,
      info: info,
    };
    //
    // logger.error(err);
    console.error("errorCaptured", err);
    this.$store.commit("app/setVueError", err);

    return true; // エラーはここで処理したとする
  },
};
</script>

<style lang="scss" scoped>
.blur {
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  filter: blur(3px);
}
</style>
