<template>
  <v-menu v-model="menu" bottom left offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" color="transparent" tile v-bind="attrs" v-on="on">
        <country-flag :country="flag(inputedValue)" size="small" />
        <v-icon v-text="menu ? `mdi-chevron-up` : `mdi-chevron-down`" />
      </v-btn>
    </template>
    <v-sheet style="overflow: auto" :height="items.length > 5 ? 200 : null">
      <v-list dense>
        <v-list-item
          v-for="(item, i) of items"
          :key="i"
          @click="inputedValue = item"
        >
          <country-flag :country="flag(item)" size="small" />
          {{ flag(item) }}
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  components: {},

  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: () => "ja",
    },

    items: {
      type: Array,
      default: () => ["ja", "en"],
    },
  },

  computed: {
    inputedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
  },

  data: () => ({
    menu: false,
  }),

  methods: {
    onLoaded() {},

    // localeとの違いをこれでクッションする
    flag(val) {
      if (val == null) val = "";
      switch (val.toLowerCase()) {
        case "ja":
          return "jp";
        case "en":
          return "us";
        default:
          return null;
      }
    },
  },
  created() {
    this.onLoaded();
  },
};
</script>
