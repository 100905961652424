import Vue from "vue";

//import { webconfig } from "@/web-configs";

// vee-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

// 言語定義ファイルの場所がマズいので変えること
import ja from "vee-validate/dist/locale/ja.json";
import en from "vee-validate/dist/locale/en.json";
// ex ↑こんな形式
// {
//   "code": "ja",
//   "messages": {
//     "alpha": "{_field_}はアルファベットのみ使用できます",
//     ...
//  }
// }

// 別のバリデータを読み込みカスタムルールに適用する
import isURL from "validator/lib/isURL";

extend("required", required);
extend("max", max);

// 多言語化なしでここでメッセージ付きでカスタムルールを追加するやりかた
// extend("required2", {
//   ...required,
//   message: "{_field_} can not be empty!",
// });

// メッセージは定義ファイルでカスタムルールを適用
// extend("required2", required);

// * カスタムルール
// messageを抜けば定義ファイル側のメッセージ文字列を参照してくれる
extend("url", {
  message: "{_field_} can  be url",
  validate(value) {
    return isURL(value, { protocols: ["https", "http"] });
  },
});

// ** メッセージを設定
// ここで設定しなかったり、キーにあう文字列がない場合は xxxxx is not valid となる
// この書き方でもいい
// localize({
//   en: en,
//   ja: ja,
// });
localize({
  en,
  ja,
});
// これはダメ
// localize({ en }, { ja });
// デフォルトlocale
localize("en"); // app.mountedで設定ファイルより読み込むためここではハードコーディングでよい

// 自分が実装したvee-validateのカスタムルールをインポート
// import * as CustomRules from '~/utils/validation-custom-rules.js'
/*
// validation-custom-rules.js

const custom_rule = {
  // ルールを書くときに使う引数。
  params: ['compare'],

  // バリデーションロジック
  validate(field, { compare }) {
    return true // or false true: バリデーションエラーなし, false: バリデーションエラーあり
  },

  // バリデーションエラーメッセージ
  message(field, { compare }){
    return `validation error. ${field}` 
  },
}

export { custom_rule }
*/

// for (const rule in CustomRules) {
//   extend(rule, CustomRules[rule])
// }

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
