import message from "./message";
import error from "./error";
import validation from "./validation";
import dictionary from "./dictionary";
import user from "./user";

export default {
  // message
  message: message,
  // error
  error: error,
  // validation
  validation: validation,
  // dictionary
  dictionary: dictionary,
  // user
  user: user,
};
