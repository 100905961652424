export default {
  language: "言語",
  sample: {
    title: "タイトル",
    message: "本文のサンプルです",
  },
  name: "名前",
  confirm: {
    commit: "登録してもよろしいですか？",
    modified: "変更されています。保存せず閉じてもよろしいですか？",
  },
};
