// Data
const state = {
  /** アクセス時に発行した固有ID */
  userCode: "",
  userKey: "",
  /** 作成したショット */
  shots: [], // プリミティブ値じゃなければCookieに保存されない(x array)
};

const mutations = {
  setUserCode(state, userCode) {
    state.userCode = userCode;
  },
  setUserKey(state, userKey) {
    state.userKey = userKey;
  },
  setShots(state, shots) {
    // const val = JSON.stringify(shots);
    // state.shots = val;
    state.shots = shots;
  },
};

const getters = {
  userCode(state) {
    return state.userCode;
  },
  userKey(state) {
    return state.userKey;
  },
  shots(state) {
    if (!state.shots || state.shots == "") return [];
    // return JSON.parse(state.shots);
    return state.shots;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
