import Vue from "vue";
import Vuex from "vuex";
//

// 設定ファイル読み込み
import { webconfig } from "@/web-configs";

// import * as Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";

//
import * as modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: modules,
  plugins: [
    // createPersistedState({
    //   key: "siteshot_session",
    //   paths: ["dataContext"],
    //   storage: window.sessionStorage,
    // }),
    createPersistedState({
      key: webconfig.storage.key,
      paths: ["storage"],
      storage: window.localStorage,
      // storage: {
      //   getItem: (key) => Cookies.get(key),
      //   setItem: (key, value) =>
      //     //7日間有効
      //     // Strict：cookie利用不可
      //     // Lax：POSTやiframe等はcookie利用不可
      //     // None：cookie利用可
      //     Cookies.set(key, value, {
      //       expires: 7,
      //       secure: true,
      //       sameSite: "None",
      //     }),

      //   removeItem: (key) => Cookies.remove(key),
      // },
    }),
  ],
});
