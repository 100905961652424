<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <slot name="default" :on="on" :attrs="attrs" :click="onLinkCopy">
        <v-icon
          class="ml-2"
          v-text="'mdi-content-copy'"
          color="success"
          v-bind="attrs"
          v-on="on"
          left
          small
          @click="onLinkCopy"
        />
      </slot>

      <v-snackbar v-model="snackbar" dark>
        <slot name="snackbar">
          <span>{{ completeMessage }}</span>
        </slot>

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <span class="text-caption">{{ message }}</span>
  </v-tooltip>
</template>
<script>
export default {
  components: {},

  props: {
    value: {
      type: [Object, String],
      default: () => null,
    },
    message: {
      type: String,
      default: () => "クリップボードにコピーします",
    },
    completeMessage: {
      type: String,
      default: () => "コピーしました",
    },
  },

  computed: {},

  data: () => ({
    snackbar: false,
  }),

  methods: {
    onLoaded() {},

    async onLinkCopy() {
      navigator.clipboard.writeText(this.value);
      //
      this.snackbar = true;
    },
  },

  watch: {
    // 表示時
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },

  created() {},
};
</script>
