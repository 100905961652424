import Vue from "vue";

//
import Toasted from "vue-toasted";
let options = {
  position: "bottom-right",
  theme: "outline",
  iconPack: "mdi",
  duration: 15000,
  action: [
    {
      text: "close", //この段階ではi18nが効かないためApp.jsで再度action登録する
      onClick: (_e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  ],
};
Vue.use(Toasted, options);

//

// import VueAdsense from "vue-adsense";
// Vue.component("adsense", VueAdsense);
import Ads from "vue-google-adsense";
Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);
//
import wordcloud from "vue-wordcloud";
Vue.component("wordcloud", wordcloud);

import WliteBox from "@/components/liteBoxies/LiteBox";
Vue.component("WliteBox", WliteBox);

import WCopyToClipBoard from "@/components/copyToClipBoards/CopyToClipBoard";
Vue.component("WCopyToClipBoard", WCopyToClipBoard);

import WFileDownload from "@/components/fileDownloads/FileDownload";
Vue.component("WFileDownload", WFileDownload);

import WColorPicker from "@/components/colorPickers/ColorPicker";
Vue.component("WColorPicker", WColorPicker);

import WCountryFlagPicker from "@/components/countryFlagPickers/CountryFlagPicker";
Vue.component("WCountryFlagPicker", WCountryFlagPicker);

import WAdsense from "@/components/adsenses/Adsense";
Vue.component("WAdsense", WAdsense);
