import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "404",
    component: () => import("@/views/Errors/404.vue"),
  },
  {
    path: "/",
    name: "",
    redirect: "/home",
    component: () => import("@/containers/TheContainer.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Homes/Home.vue"),
      },
      {
        path: "/shot/:shortCode/:secretCode?/:securityCode?",
        name: "Shot",
        component: () => import("@/views/Shots/Shot.vue"),
      },
    ],
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("@/views/Policies/Policy.vue"),
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("@/views/Services/Service.vue"),
  },
  {
    path: "/sample",
    name: "Sample",
    component: () => import("@/views/Samples/Sample.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
