import Vue from "vue";

import axios, { AxiosError } from "axios"; // axios本体
import VueAxios from "vue-axios"; // vue内でthis.axiosで使用できるようにさせる

// import i18n from "@/i18n";

let md5 = require("md5");
// const { v4: uuidv4 } = require("uuid");

import { decrypt } from "@/util/encryp";

// apikeyが指定のキーを暗号化する(サーバ側と同様のアルゴリズム)
function encriptedApikey(key) {
  return key;
  /*
  const code = uuidv4().replace(/-/g, "");
  const code1 = code.substring(0, 16);
  const code2 = code.substring(code.length - 16);
  const unixTime = new Date().getTime();
  const b = Math.floor(unixTime / 1000 / 100); // 末尾1桁切り捨て=100秒のタイムラグは許容
  const str = code + key + b;

  return code1 + md5(str) + code2;
  */
}

// WebAPI側のRouteはビルド時に決まるため動的には変更できない
export function encryptUrl(val, key) {
  // キーが未設定の場合は暗号化しない
  if (key == null || key == "") return val;

  // Controller部分とActionを分離
  let urlArgs = val.split("/");

  let controller = urlArgs[0];

  let action = "";
  if (urlArgs.length >= 2) action = val.replace(`${controller}/`, "");
  else action = val.replace(`${controller}`, "");

  let newUrl = md5(`${controller}${key}`.toLowerCase());
  newUrl += `/` + md5(`${action}${key}`.toLowerCase());

  // console.info("encryptUrl:", {
  //   val: val,
  //   key: key,
  //   urlArgs: urlArgs,
  //   controller: controller,
  //   action: action,
  //   newUrl: newUrl,
  // });

  return newUrl;
}

// importした時点で内部でVue.useを行っている
// オブジェクトがno usedになるためVueのコンストラクタに渡している
import Application from "@/applications/Application";
import {
  AppException,
  AppValidateException,
  AppUnauthorizedException,
  AppForbiddenException,
  AppNotfoundException,
  AppGoneException,
  AppTooManyRequestsException,
  AppSystemException,
} from "@/exceptions/appExceptions";

// 設定ファイル読み込み
import { webconfig } from "@/web-configs";

// axiosのデフォルト設定(動的に変わらない部分の設定)
axios.defaults.headers.common["x-api-key"] = webconfig.api.xApiKey;
axios.defaults.baseURL = webconfig.api.baseURL;
// axios.defaults.timeout = 200000;

axios.interceptors.request.use((config) => {
  // ローディング開始
  Application.app.loading.show = true;

  // 動的に変わる部分をインターセプトで設定
  config.headers["Accept-Language"] = Application.app.locale;
  config.headers["apikey"] = encriptedApikey(webconfig.api.apikey);
  // config.headers["Authorization"] = `bearer `;
  // config.headers["usercode"] = usercode;

  // url暗号化(baseUrlを使用したアクション指定の場合のみ)
  console.debug("request:url(original)", `${config.baseURL}/${config.url}`);
  if (
    config.url != null &&
    !config.url.startsWith("http://") &&
    !config.url.startsWith("https://")
  ) {
    config.url = encryptUrl(config.url, webconfig.api.urlEncryptKey);
  }

  console.debug("interceptors.request", config);

  //
  return config;
});
axios.interceptors.response.use(
  async (response) => {
    console.debug("interceptors.response", response);

    // 暗号化して送信していたらレスポンスは複合化する
    if (response.headers["siteshot-encrypted"] == "true") {
      const data = response.data;
      const dec = decrypt(
        data,
        webconfig.api.responseEcrypt.iv,
        webconfig.api.responseEcrypt.key
        // "bbbbbbbbbbbbbbbb",
        // "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
      );
      response.data = JSON.parse(dec);

      console.debug("interceptors.response decrypted", response);
    }

    // ローディング完了
    Application.app.loading.show = false;

    //
    return response;
  },
  async (error) => {
    console.error("interceptors.response.error", error);

    // ApiGateWayはLambda以前(CloudfrontやApiGatewayの設定)で4xx,5xx系を返す時、CORSヘッダを返さないのでCORSがおこる
    // なので、Gateway Responses->4xx, 5xx で以下のように 設定しておく
    // Access-Control-Allow-Origin	'*'
    // https://stackoverflow.com/questions/65501665/aws-api-gateway-429-response-no-cors-headers
    //
    Application.app.loading.show = false; // ローディング完了

    // API側のエラーレスポンスがない(axios処理中のエラー)はそのまま再スロー
    if (error.response == null || error.response.data == null) {
      // AxiosErrorはcode,messageを持つのでそれをエラーとして使用
      if (error instanceof AxiosError) {
        // 通信失敗
        const message = Application.app.trans("E01001", {
          message: `[${error.code}]${error.message}`,
        });

        // エラーダイアログ表示
        Application.app.showError(message.code, message.title, message.message);
        // 後続に例外を投げる
        return Promise.reject(
          new AppException(
            message.code,
            message.message,
            message.approach,
            message.errors
          )
        );
      } else {
        // 原因不明のエラー:メッセージ情報がない内容は「unknown」という文字列
        const message = Application.app.trans("E00001", {
          code: "unknown",
          title: "unknown",
          message: "unknon error",
        });
        // エラーダイアログ表示
        Application.app.showError(message.code, message.title, message.message);
        // 後続に例外を投げる
        return Promise.reject(
          new AppException(
            message.code,
            message.message,
            message.approach,
            message.errors
          )
        );
      }
    }

    // API側のエラーレスポンスの中身を再スローさせる
    let data = error.response.data;
    // バリデーションエラーはAppValidateExceptionをcatchに渡し処理側に任せる
    if (!data) {
      // 万が一ここでもdataがカラの場合(ExceptionFilter以降でエラーした場合など)
      data = Application.app.trans("E00003", {
        code: "unknown",
        title: "unknown",
        message: "unknon error",
      });
    }

    // 多言語化されていない場合コードからdataに変換
    if (!data.localized) {
      // 対応するコードがなければデフォルトは与えられたタイトルとメッセージなどに設定
      const message = Application.app.trans(data.code, {
        title: data.title,
        message: data.message,
        approach: data.approach,
      });
      data.title = message.title;
      data.message = message.message;
      data.approach = message.approach;
      //
    }

    // トースターを表示しエラー通知の取りこぼしが無いようにする
    Application.app.showErrorToaster(data.code, data.title, data.message);

    // ステータスコードで再スローする例外クラス分け
    switch (error.response.status) {
      case 400: {
        // エラーハンドル完了せず後続につなげる
        return Promise.reject(
          new AppValidateException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }
      case 401: {
        //
        return Promise.reject(
          new AppUnauthorizedException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }
      case 403: {
        //
        return Promise.reject(
          new AppForbiddenException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }

      case 404: {
        //
        return Promise.reject(
          new AppNotfoundException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }
      case 410: {
        //
        return Promise.reject(
          new AppGoneException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }
      case 429: {
        //
        return Promise.reject(
          new AppTooManyRequestsException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );
      }
      case 500:
      default:
        // エラー

        return Promise.reject(
          new AppSystemException(
            data.code,
            data.message,
            data.approach,
            data.errors
          )
        );

      // これを返さなければ例外を処理したとみなされる
      // return Promise.reject(error.response.data);
    }
  }
);
Vue.use(VueAxios, axios);
