import Vue from "vue";
import VueI18n from "vue-i18n";

// import { webconfig } from "@/web-configs";

const messages = {
  en: require("@/i18n/en").default,
  ja: require("@/i18n/ja").default, // もしくはimport ja from '@/i18n/ja'として jaを入れる
};

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en", // app.mountedで設定ファイルより読み込むためここではハードコーディングでよい
  messages,
});

// ここが役に立つ(3と4では違うらしい。本プロジェクトは3)
//https://technologyagency.net/wordpress/2022/04/08/vee-validate-3%E3%81%8B%E3%82%894%E3%81%AB%E3%82%A2%E3%83%83%E3%83%97%E3%82%B0%E3%83%AC%E3%83%BC%E3%83%89%E3%81%99%E3%82%8B/
