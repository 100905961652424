// import store from "@/store";

// import { PublishTypes } from "@/types";

// import AppValidateException from "@/exceptions/AppValidateException";
// const { v4: uuidv4 } = require("uuid");

export default class DataContext {
  constructor(http, app, storage) {
    this.http = http;
    this.app = app;
    this.storage = storage;
  }
}
