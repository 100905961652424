let moment = require("moment-timezone");
//
import { marked } from "marked";
import hljs from "highlight.js";

import { thermo, rgb2hex } from "@/util/color";

export function nl2br(value) {
  if (!value) {
    return "";
  }

  return value.replace(/\n/g, "<br/>");
}

export function md(value) {
  if (!value) {
    return "";
  }

  //

  //

  value = marked(value, {
    langPrefix: "",
    highlight: function (code, lang) {
      return hljs.highlightAuto(code, [lang]).value;
    },
  });

  value = nl2br(value);

  return value;
}

export function deHtmlTag(value) {
  return value.replace(/(<([^>]+)>)/gi, "");
}

export function lessStr(value, length = 80, str = "...") {
  if (!value) {
    return "";
  }

  if (value.length > length) {
    return value.slice(0, length) + str;
  }
  return value;
}

// 単純に1000で割る
// export function fileSize(x,size=1024) {
export function fileSize(x, size = 1000) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= size && ++l) {
    n = n / size;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export function dateTime(date, format = "YYYY/MM/DD HH:mm:ss") {
  if (!date) return "";
  let m = moment(date);
  if (!m.isValid()) return null;
  return m.format(format); // eslint-disable-line
}

export function fromNow(date) {
  if (date == null) return null;

  return moment(date).fromNow();
}

export function number(value) {
  if (value == undefined || value == null) {
    return "";
  }
  //
  value = Math.floor(value);

  let val = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return val;
}

export function thermoReverseColor(value) {
  if (!value) value = 0;
  // 1に近いほど赤くなるので、逆のパターンにさせる
  let par = Math.abs(value - 100) / 100;
  let colors = thermo(par);
  // return colors;
  return rgb2hex(colors);
}

export function thermoColor(value) {
  if (!value) value = 0;
  // 1に近いほど赤くなる
  let colors = thermo(value);
  // return colors;
  return rgb2hex(colors);
}

export function isWhiteText(hexcolor, def = true) {
  if (hexcolor == null) return def;

  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(5, 2), 16);

  return (r * 299 + g * 587 + b * 114) / 1000 < 128;
}

export function contentTypeIcon(value) {
  //
  let val = value;
  if (val == null) val = "";
  //
  if (val.startsWith("image/")) return "mdi-file-image";
  switch (val) {
    case "text/plain":
      return "mdi-file-document-outline";
    case "text/html":
      return "mdi-file-code-outline";
    default:
      return "mdi-file-alert-outline";
  }
}

export function noData(value, label = "-") {
  if (!value) return label;
  return value;
}
