import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import { webconfig } from "@/web-configs";

// jsレベルのエラーハンドリング
window.onerror = (message, source, lineno, colno, error) => {
  const err = {
    show: true,
    message: message,
    source: source,
    lineno: lineno,
    colno: colno,
    error: error,
  };
  // logger.error(error);
  console.error("window.onerror", err);
  this.$store.commit("app/setJsError", err);

  return true; // エラーはここで処理したとする
};
//
import Mixin from "./mixins/mixin";
Vue.mixin(Mixin);

Vue.config.productionTip = false;

// 自作フィルタ
import * as filters from "@/filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// i18nは最終的にVueに組み込むが、他のVueに依存しないクラスでも使用されるので
// グローバルな宣言を行った状態のものを各所で読み込むことにする
import i18n from "@/i18n";

// async対応
import "babel-polyfill";

// axios
require("./main-import.axios");
// validate
require("./main-import.validate");

// vue-moment,moment-timezone
let moment = require("moment-timezone"); // タイムゾーンに合わせたmomentライブラリを使用する
Vue.use(require("vue-moment"), {
  moment,
});
moment.locale("en"); // app.mountedで設定ファイルより読み込むためここではハードコーディングでよい

// import
require("./main-import");

// disqus
// import VueDisqus from "vue-disqus";
// Vue.use(VueDisqus, {
//   shortname: "siteshot",
// });

import XDisqus from "@/components/disqus/Disqus";
Vue.component("XDisqus", XDisqus);

// うまく使えない・・
import VueDisqus from "vue-disqus";
Vue.use(VueDisqus, {
  shortname: "siteshot",
});

// https://github.com/AurityLab/vue-recaptcha-v3/tree/vue-v2.x
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: webconfig.google.recaptcha.siteKey });

//
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: {
      id: webconfig.google.analytics.id,
    },
  },
  router
);

// https://zenn.dev/naoki_oshiumi/articles/776b9052f47d52
import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading, {
  /* options */
});
Vue.component("InfiniteLoading", InfiniteLoading);

// https://www.npmjs.com/package/vue-country-flag
import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);

new Vue({
  router,
  store,
  vuetify,
  i18n, // これでthis.$i18nで使える
  render: (h) => h(App),
}).$mount("#app");
