import store from "@/store";

import Vue from "vue";

// 多言語化変更用
import i18n from "@/i18n";
import { localize } from "vee-validate";
const moment = require("moment-timezone");

// import {
//   AppValidateException,
//   AppSystemException,
// } from "@/exceptions/appExceptions";

//
const format = require("string-format");

// 設定ファイル読み込み
import { webconfig } from "@/web-configs";

export default class App {
  constructor(http) {
    this.http = http;
  }

  /** アプリケーションのバージョン */
  get version() {
    // return webconfig.web.version;
    return require("../../package.json").version;
  }

  get initializing() {
    return store.getters["app/initializing"];
  }

  set initializing(value) {
    store.commit("app/setInitializing", value);
  }

  get loading() {
    return store.getters["app/loading"];
  }

  set loading(value) {
    store.commit("app/setLoading", value);
  }

  get fatal() {
    return store.getters["app/fatal"];
  }

  set fatal(value) {
    store.commit("app/setFatal", value);
  }

  get error() {
    return store.getters["app/error"];
  }

  set error(value) {
    store.commit("app/setError", value);
  }

  get invalid() {
    return store.getters["app/invalid"];
  }

  set invalid(value) {
    store.commit("app/setInvalid", value);
  }

  get message() {
    return store.getters["app/message"];
  }

  set message(value) {
    store.commit("app/setMessage", value);
  }
  get toaster() {
    return store.getters["app/toaster"];
  }

  set toaster(value) {
    store.commit("app/setToaster", value);
  }

  get setting() {
    return store.getters["app/setting"];
  }

  set setting(value) {
    store.commit("app/setSetting", value);
  }

  get locale() {
    return store.getters["app/locale"];
    // return i18n.locale;
  }
  set locale(val) {
    // viewのi18nの設定
    i18n.locale = val;
    // vee-validateのi18n設定
    localize(val);
    // momentのi18ｎ設定
    moment.locale(val);
    // このプロジェクトのmomentはこっちの実装でもOK
    // Vue.moment.locale(val);

    store.commit("app/setLocale", val);
  }

  // 画面をブロックしてエラーを表示します(閉じるがないので継続不能)
  showFatal(code, title, message, approach) {
    this.fatal.code = code;
    this.fatal.title = title;
    this.fatal.message = message;
    this.fatal.approach = approach;
    this.fatal.show = true;
  }
  // エラーダイアログを表示します
  showError(code, title, message, approach) {
    this.error.code = code;
    this.error.title = title;
    this.error.message = message;
    this.error.approach = approach;
    this.error.show = true;
  }

  // メッセージダイアログを表示します
  showMessage(code, title, message, approach = null) {
    this.message.type = "dialog";
    this.message.code = code;
    this.message.title = title;
    this.message.message = message;
    this.message.approach = approach;
    this.message.show = true;
  }

  // snackbarを表示します
  showInvalid(code, title, message, errors) {
    this.invalid.code = code;
    this.invalid.title = title;
    this.invalid.message = message;
    this.invalid.errors = errors;
    this.invalid.show = true;
  }

  // toasterを表示します
  showToaster(code, title, message, approach = null) {
    this.toaster.code = code;
    this.toaster.title = title;
    this.toaster.message = message;
    this.toaster.approach = approach;

    let toastedMessage = "";
    if (this.toaster.code) toastedMessage += `${this.toaster.code}: `;
    if (this.toaster.message) toastedMessage += `${this.toaster.message} `;
    Vue.toasted.show(toastedMessage, {});
    // toastedはライブラリ処理のためmessage.showは行わない
  }

  // error toasterを表示します
  showErrorToaster(code, title, message, approach = null) {
    this.toaster.code = code;
    this.toaster.title = title;
    this.toaster.message = message;
    this.toaster.approach = approach;

    let toastedMessage = "";
    if (this.toaster.code) toastedMessage += `${this.toaster.code}: `;

    // タイトルがあるがmessageがない場合
    if (this.toaster.title && !this.toaster.message)
      toastedMessage += `${this.toaster.title} `;

    if (this.toaster.message) toastedMessage += `${this.toaster.message} `;
    Vue.toasted.show(toastedMessage, {
      // icon: "mdi-alert-outline", // アイコンを入れるとaignが崩れるのでやめておく
      type: "error",
      action: [
        {
          text: this.trans("MP0001", { message: "close" }).message,
          onClick: (_e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      ],
    });
    // toastedはライブラリ処理のためmessage.showは行わない
  }

  async loadI18n() {
    try {
      // const response = await this.http.get("application/messages");
      // const data = response.data;

      // const data = require("./messages/message.json");

      //
      const response = await this.http.get(webconfig.resource.message);
      const data = response.data;
      Object.keys(data).forEach((key) => {
        // 言語名に対してメッセージデータを設定
        i18n.setLocaleMessage(key, data[key]);
      });
    } catch (_ex) {
      console.error("loadI18n error", _ex);
      // 言語ファイルが取得できない場合はスナックバーを出してデフォルトに任せる
      const message = this.trans("E10011", {
        title: "",
        message: "load message failed.",
      });
      this.showErrorToaster(message.code, message.title, message.message);
    }
  }

  async loadAppSetting() {
    try {
      //
      const response = await this.http.get("setting");
      const data = response.data;
      this.setting = data;
    } catch (_ex) {
      console.error("loadAppSetting error", _ex);
      // 言語ファイルが取得できない場合はスナックバーを出してデフォルトに任せる
      const message = this.trans("E10012", {
        title: "",
        message: "load setting failed.",
      });
      this.showErrorToaster(message.code, message.title, message.message);
    }
  }

  async loadFaq() {
    try {
      //
      const response = await this.http.get(webconfig.resource.faq);
      return response.data;
    } catch (_ex) {
      console.error("loadFaq error", _ex);
      // 言語ファイルが取得できない場合はスナックバーを出してデフォルトに任せる
      const message = this.trans("E10013", {
        title: "",
        message: "load faq failed.",
      });
      this.showErrorToaster(message.code, message.title, message.message);
    }
  }
  async loadReleaseNote() {
    try {
      //
      const response = await this.http.get(webconfig.resource.releaseNote);
      return response.data;
    } catch (_ex) {
      console.error("loadReleaseNote error", _ex);
      // 言語ファイルが取得できない場合はスナックバーを出してデフォルトに任せる
      const message = this.trans("E10014", {
        title: "",
        message: "load release note failed.",
      });
      this.showErrorToaster(message.code, message.title, message.message);
    }
  }

  async loadPolicy() {
    try {
      const index = await this.http.get(webconfig.resource.policyIndex);
      const body = await this.http.get(webconfig.resource.policy);

      return {
        index: index.data,
        body: body.data,
      };
    } catch (_ex) {
      console.error({ _ex });
      //
      const message = this.trans("E10018", {
        title: "",
        message: "load policy failed.",
      });
      this.showToaster(message.code, message.title, message.message);
    }
  }

  async loadSns() {
    try {
      const response = await this.http.get(webconfig.resource.sns);

      return response.data;
    } catch (_ex) {
      console.error({ _ex });
      //
      const message = this.trans("E10018", {
        title: "",
        message: "load sns failed.",
      });
      this.showToaster(message.code, message.title, message.message);
    }
  }

  trans(
    code,
    defaultData = {
      code: null,
      title: "",
      message: "",
      approach: "",
    },
    args = {}
  ) {
    if (!i18n.t || !i18n.te) return defaultData;
    if (!code) return defaultData;
    // デフォルトのcode表記指定がない場合はアスタリスク付きコードを返す
    if (!defaultData.code) defaultData.code = `*${code}`;
    console.debug("trans defaultData", defaultData);

    const isDictionary = code.startsWith("D");
    const isError = code.startsWith("E");
    const isMessage = code.startsWith("M");
    const isUser = code.startsWith("U");
    //
    let key = null;
    if (isDictionary) key = format("dictionary.{}", code);
    else if (isError) key = format("error.{}", code);
    else if (isMessage) key = format("message.{}", code);
    else if (isUser) key = format("user.{}", code);
    console.debug("trans key", key);
    //
    let data = i18n.te(key) ? i18n.t(key, i18n.locale, args) : defaultData;
    // コード追記する
    data.code = code;
    //
    return data;
  }

  transMessage(code, defaultMessage = null) {
    const message = this.trans(code, { message: `*${defaultMessage}` }); // コードがない場合はデフォルトメッセージに*をつける
    return message.message;
  }

  confirm(code, defaultMessage = null) {
    return confirm(this.transMessage(code, defaultMessage));
  }
}
