<template>
  <v-btn
    icon
    class="text-none"
    color="light-blue lighten-2"
    @click="onDownload"
  >
    <v-icon v-text="'mdi-tray-arrow-down'" left />
  </v-btn>
</template>
<script>
export default {
  components: {},

  props: {
    src: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
  },

  computed: {},

  data: () => ({}),

  methods: {
    onLoaded() {},

    onDownload() {
      //
      let link = document.createElement("a");
      link.href = this.src;
      link.download = this.fileName;
      link.click();
    },
  },

  watch: {
    // 表示時
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },

  created() {},
};
</script>
