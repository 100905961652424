import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import ja from "vuetify/src/locale/ja.ts";
import Colors from "vuetify/lib/util/colors";

export default new Vuetify({
  lang: {
    locales: { ja },
    current: "ja",
  },
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: {
          base: Colors.blue.base,
          darken1: Colors.blue.darken4,
        },
        secondary: "#8bc34a",
        accent: "#cddc39",
        error: "#ffeb3b",
        warning: "#ffc107",
        // info: "#ff5722",
        success: "#795548",
        //
      },
      dark: {
        // primary: '#ff9800',
        // secondary: '#ff5722',
        // accent: '#795548',
        // error: '#f44336',
        // warning: '#ffc107',
        // info: '#2196f3',
        // success: '#4caf50',
      },
    },
    /*

    // 和色 https://www.colordic.org/w
    themes: {
      light: {
        primary: "#0d0015",// 漆黒
        secondary: "#8b968d",// 豆がら茶
        accent: "#d6e9ca", // 白緑
        error: "#ea5506", // 赤橙
        warning: "#f08300", // 蜜柑色
        info: "#e7e7eb", // 紫水晶
        success: "#2ca9e1", // 天色

        title:"#ffffff", // 白
       
       


      },
      dark: {
        // primary: "#eaf4fc",//月白
        primary: "#ffffff",//白
        secondary: "#d6e9ca",// 白緑
        accent: "#d6e9ca", // 白緑
        error: "#ea5506", // 赤橙
        warning: "#f08300", // 蜜柑色
        info: "#e7e7eb", // 紫水晶
        success: "#2ca9e1", // 天色


        title:"#0d0015",// 漆黒



      },
    },
    */
  },
});
