export default {
  E00001: {
    title: "不明{val1}{val2}",
    message: "判別不明なエラーが発生しました。",
    approach: "判別不明なエラーが発生しました。",
  },
  E00002: {
    title: "不明2",
    message: "判別不明なエラーが発生しました。",
    approach:
      '暫くたってから再度お試しください。解消しない場合は<a href="Contact" target="_blank">お問い合わせ</a>から情報をお教えください',
  },
  EDAI01: {
    title: "アイテムファイル削除エラー",
    message: "ファイルが削除できません",
    approach:
      "アイテムは最低一つ以上のファイルが必要です。削除する場合、別の画像をアップロードしてから削除してください",
  },

  400: {
    title: "",
    message: "アクセス情報が正しくありません",
    approach: "正しくアクセスしてください",
  },
  401: {
    title: "",
    message: "このコンテンツはアクセスできません",
    approach: "",
  },
  403: {
    title: "",
    message: "このコンテンツはアクセスできません",
    approach: "",
  },
  404: {
    title: "",
    message: "このコンテンツは存在しません",
    approach: "",
  },

  410: {
    title: "削除済み",
    message: "このコンテンツは削除されているためアクセスできません",
    approach: "",
  },

  429: {
    title: "アクセス過多",
    message: "アクセスが集中しているためページが表示できません",
    approach: "しばらくたってからページを更新してください。",
  },
};
